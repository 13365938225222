<script setup lang="ts">
    import { useI18n } from 'vue-i18n';

    defineProps<{
        letterCheck: boolean;
        numberCheck: boolean;
        lengthCheck: boolean;
    }>();

    const { t } = useI18n();
</script>

<template>
    <div>
        <p class="small-secondary-text">{{ t('FORM.PASSWORD_VALIDATION.REQUIRED') }}</p>
        <ul class="small-secondary-text">
            <li :class="letterCheck ? 'strikethrough' : ''">
                {{ t('FORM.PASSWORD_VALIDATION.LETTER') }}
            </li>
            <li :class="numberCheck ? 'strikethrough' : ''">
                {{ t('FORM.PASSWORD_VALIDATION.NUMBER') }}
            </li>
            <li :class="lengthCheck ? 'strikethrough' : ''">
                {{ t('FORM.PASSWORD_VALIDATION.LENGTH') }}
            </li>
        </ul>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .small-secondary-text {
        font-size: main.$font-size-sm;
        color: main.$color-secondary;
        margin: 0;
    }

    .strikethrough {
        text-decoration: line-through;
    }
</style>
