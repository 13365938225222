import { computed, ref, type ComputedRef } from 'vue';

interface PasswordRequirementState {
    letterCheck: ComputedRef<boolean>;
    numberCheck: ComputedRef<boolean>;
    lengthCheck: ComputedRef<boolean>;
    validatorFn(this: void, value: string): boolean;
}

const letterRegex = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
const numberRegex = /^(?=.*\d).+$/;
const lengthRegex = /^.{8,}$/;

export function passwordRequirements(): PasswordRequirementState {
    const letterCheck = ref(false);
    const numberCheck = ref(false);
    const lengthCheck = ref(false);

    return {
        letterCheck: computed(() => letterCheck.value),
        numberCheck: computed(() => numberCheck.value),
        lengthCheck: computed(() => lengthCheck.value),
        validatorFn(value) {
            letterCheck.value = letterRegex.test(value);
            numberCheck.value = numberRegex.test(value);
            lengthCheck.value = lengthRegex.test(value);

            return letterCheck.value && numberCheck.value && lengthCheck.value;
        },
    };
}
